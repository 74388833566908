@import "../../../data/styles.css";

.article {
	display: flex;
}

.article a {
	text-decoration: none;
}

.article-left-side {
	min-width: 20%;
	max-width: 20%;
}

.article-right-side {
	flex-basis: 80%;
	min-width: 700px;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	padding: 30px;
}

.article-right-side:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.article-date {
	padding-top: 30px;
	font-size: 15px;
	color: var(--tertiary-color);
}

.article-title {
	color: #000000;
	font-size: 16px;
	font-weight: 600;
}

.article-location{
	padding-top: 5px;
	color: var(--secondary-color);
	font-size: 12px;
	font-weight: 600;
}

.article-description {
	padding-top: 10px;
	font-size: 12px;
	color: var(--secondary-color);
	font-weight: 480;
	line-height: 25px;
}

.article-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

@media (max-width: 1024px) {
	.article-left-side {
		min-width: 10%;
		max-width: 10%;
	}

	.article-right-side {
		flex-basis: 90%;
		min-width: 85%;
		max-width: 85%;
	}
}
